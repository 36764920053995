import Button from 'react-bootstrap/Button';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Image } from '../../types/issue';

export interface IssueImageViewProps extends ModalProps {
  image?: Image;
}

function IssueImageView(props: IssueImageViewProps) {
  const modelProps: ModalProps = { ...props };
  delete modelProps.image;
  return (
    <Modal {...modelProps} fullscreen={true}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        {props.image?.url === undefined && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {props.image?.url !== undefined && <img src={props.image?.url} alt={props.image?.path} />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IssueImageView;
