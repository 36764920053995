import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

export interface LeftAdminNavProps {
  projects: Array<{ id: string; name: string }>;
  onChange: (projectId: string) => void;
}

export default function LeftAdminNav(props: LeftAdminNavProps) {
  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(e.target.value);
  }
  return (
    <>
      <Container>
        <ul className="list-unstyled">
          <li>
            <a href={`#Toggle`} data-bs-toggle="collapse" role="button" aria-controls={`Toggle`} className="btn-toggle">
              Observations Review
            </a>
            <ul id={`Toggle`} className="submenu collapse">
              <li>
                <Form.Select aria-label="Projects" onChange={handleChange}>
                  <option>Select a project</option>
                  {props.projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </Form.Select>
              </li>
            </ul>
          </li>
        </ul>
      </Container>
    </>
  );
}
